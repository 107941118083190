import { useMemo } from 'react';
import { Row, Column } from "../../layout/components/dom";
import { useGlobal } from "../../utility/CTX";









export const SectionComponent = ({article, section, index}) => {
    const { theme, dispatch } = useGlobal();

    return <Row style={{justifyContent: 'space-between'}}>

        {section.type === 'header' && <HeaderSectionComponent article={article} section={section} index={index} />}
        {section.type === 'paragraph' && <ParagraphSectionComponent article={article} section={section} index={index} />}
        {section.type === 'image' && <ImageSectionComponent article={article} section={section} index={index} />}
        {section.type === 'video' && <VideoSectionComponent article={article} section={section} index={index} />}
        {section.type === 'quote' && <QuoteSectionComponent article={article} section={section} index={index} />}
        {section.type === 'list' && <ListSectionComponent article={article} section={section} index={index} />}
        {section.type === 'table' && <TableSectionComponent article={article} section={section} index={index} />}
        {section.type === 'embed' && <EmbedSectionComponent article={article} section={section} index={index} />}
        {section.type === 'divider' && <DividerSectionComponent article={article} section={section} index={index} />}
        {section.type === 'gallery' && <GallerySectionComponent article={article} section={section} index={index} />}
        {section.type === 'callout' && <CalloutSectionComponent article={article} section={section} index={index} />}
    
    </Row>
};



export const makeHeaderId = (header) => {
    return `#header-${header.toLowerCase().replaceAll(' ', '-')}`;
};




const HeaderSectionComponent = ({ section }) => {
    const headerId = useMemo(() => makeHeaderId(section.content).replaceAll('#', ''), [section?.content])
    return <>
        {section.level === 1 && (
            <h1 id={headerId} style={{ flexGrow: 1, margin: '10px 0px' }}>
                {section.content}
            </h1>
        )}
        {section.level === 2 && (
            <h2 id={headerId} style={{ flexGrow: 1, margin: '10px 0px' }}>
                {section.content}
            </h2>
        )}
        {section.level === 3 && (
            <h3 id={headerId} style={{ flexGrow: 1, margin: '10px 0px' }}>
                {section.content}
            </h3>
        )}
        {section.level === 4 && (
            <h4 id={headerId} style={{ flexGrow: 1, margin: '10px 0px' }}>
                {section.content}
            </h4>
        )}
        {section.level === 5 && (
            <h5 id={headerId} style={{ flexGrow: 1, margin: '10px 0px' }}>
                {section.content}
            </h5>
        )}
        {section.level === 6 && (
            <h6 id={headerId} style={{ flexGrow: 1, margin: '10px 0px' }}>
                {section.content}
            </h6>
        )}
    </>
};



const ParagraphSectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    return <p style={{outline: 'none', margin: 10}}>
        {section.content}
    </p>;
};





const ImageSectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    // button to select images from firebase storage

    return <div>
        <img src={section.src} alt={section.alt} />
        {section.caption && <p>{section.caption}</p>}
    </div>

};





const VideoSectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    return <Column style={{flexGrow: 1}}>
        <video controls style={{flexGrow: 1, borderRadius: 5, border: `1px solid ${theme.border}`}}>
            <source src={section.src} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
        {section.caption && <p>{section.caption}</p>}
    </Column>
};


const QuoteSectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    return <blockquote style={{ flexGrow: 1 }}>
        <p style={{ flexGrow: 1, outline: 'none', fontStyle: 'italic' }}>
            {section.content}
        </p>

        <footer style={{ flexGrow: 1, marginLeft: 100, outline: 'none' }}>
            - {section.author}
        </footer>
    </blockquote>
};




const ListSectionComponent = ({ section }) => {
    return <div style={{ flexGrow: 1 }}>
        {section.style === 'ordered' ? (
            <ol>
                {section.items.map((item, i) => (
                    <li key={i} style={{ flexGrow: 1 }}>
                        {item}
                    </li>
                ))}
            </ol>
        ) : (
            <ul>
                {section.items.map((item, i) => (
                    <li key={i} style={{ flexGrow: 1 }}>
                        {item}
                    </li>
                ))}
            </ul>
        )}
    </div>
};



const TableSectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    return <table>
        <thead>
            <tr>
                {section.headers.map((header, i) => (
                    <th key={i}>{header}</th>
                ))}
            </tr>
        </thead>
        <tbody>
            {section.rows.map((row, i) => (
                <tr key={i}>
                    {row.map((cell, j) => (
                        <td key={j}>{cell}</td>
                    ))}
                </tr>
            ))}
        </tbody>
    </table>
};




const EmbedSectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    return (
        <div>
            <iframe
                src={section.src}
                title={section.caption || 'Embedded content'}
                width="100%"
                height="400px"
                frameBorder="0"
                allowFullScreen
            />
            {section.caption && <p>{section.caption}</p>}
        </div>
    );
};





const DividerSectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    return <hr style={{width: '90%', background: theme.border, height: 0}} />;
};




const GallerySectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    return <div className="gallery">
        {section.images.map((image, i) => (
            <div key={i} className="gallery-item">
                <img src={image.src} alt={image.alt || `Image ${i + 1}`} />
            </div>
        ))}
        {section.caption && <p>{section.caption}</p>}
    </div>
};



const CalloutSectionComponent = ({ article, section, index }) => {
    const { theme, dispatch } = useGlobal();

    return (
        <div className={`callout callout-${section.style}`}>
            <p>{section.content}</p>
        </div>
    );
};





