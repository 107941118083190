import React, { useState, createContext, useContext, useReducer } from 'react'
import { useEffect } from 'react';


export const GlobalContext = createContext();
export const useGlobal = () => {
    let { state, dispatch } = useContext(GlobalContext);
    return { ...state, dispatch };
};



const combineReducers = (reducers) => {
    return (state, action) => {
      return Object.keys(reducers).reduce((nextState, key) => {
        nextState[key] = reducers[key](state[key], action);
        return nextState;
      }, {});
    };
};



export const GlobalContextProvider = ({ reducers, initialState, children }) => {
    const [state, dispatch] = useReducer(combineReducers(reducers), initialState);
  
    // Middleware to handle async actions
    const enhancedDispatch = async (action) => {
      if (typeof action === 'function') {
        await action(dispatch);
      } else {
        dispatch(action);
      }
    };



    const handleResize = () => {
        dispatch({ type: 'SET_IS_MOBILE', value: window.innerWidth <= 768});
    };


    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])



    return (
        <GlobalContext.Provider value={{ state, dispatch: enhancedDispatch }}>
            {children}
        </GlobalContext.Provider>
    );
};