import { selectTheme } from "../styles";




const initialThemeState = selectTheme();


const themeReducer = ( state = initialThemeState, action ) => {
    switch (action.type) {
        case 'SET_THEME': {
            return action.theme || state;
        };
        case 'UPDATE_THEME': {
            return { ...state, ...action.colors };
        }
        default: {
            return state;
        }
    }
};



export { initialThemeState, themeReducer };