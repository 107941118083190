import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './header.scss';
import { useGlobal } from '../utility/CTX';
import { Row, Trademark } from './components/dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { cloud } from '../utility/firebase';




function handleLinkClick(e, page, dispatch, isMobile, setDropdownOpen) {
  dispatch({ type: 'SET_PAGE', page });
  if (isMobile) setDropdownOpen(false);
}


function linkStyle(currentPage, page, theme) {
  return {
    transition: 'color 0.5s ease',
    paddingLeft: 15,
    color: currentPage === page ? theme.orange : 'inherit',
    display: 'block',
    padding: '10px 15px'
  };
}


const NavOptions = ({isMobile, setDropdownOpen}) => {
  const { app, theme, dispatch } = useGlobal();

  return <>
    <Link style={linkStyle(app.page, 'Home', theme)} to="/" onClick={(e) => handleLinkClick(e, 'Home', dispatch, isMobile, setDropdownOpen)}>
      Home
    </Link>
    <Link style={linkStyle(app.page, 'Waitlist', theme)} to="/waitlist" onClick={(e) => handleLinkClick(e, 'Waitlist', dispatch, isMobile, setDropdownOpen)}>
      Waitlist
    </Link>
    <Link style={linkStyle(app.page, 'Blog', theme)} to="/blog" onClick={(e) => handleLinkClick(e, 'Blog', dispatch, isMobile, setDropdownOpen)}>
      Blog
    </Link>
    <Link style={linkStyle(app.page, 'About', theme)} to="/about" onClick={(e) => handleLinkClick(e, 'About', dispatch, isMobile, setDropdownOpen)}>
      About
    </Link>
    <Link style={linkStyle(app.page, 'Contact', theme)} to="/contact" onClick={(e) => handleLinkClick(e, 'Contact', dispatch, isMobile, setDropdownOpen)}>
      Contact
    </Link>
    
  </>
}







function Header() {
  const { app, theme, dispatch } = useGlobal();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [logo, setLogo] = useState('');


  useEffect(() => {
    const fetch_mockups = async () => {
        try {      
            const storageRef = ref(cloud, 'site/assets/images/logo_transparent.png');
            const url = await getDownloadURL(storageRef);
            setLogo(url);
        } catch (error) {
            console.log(error);
        }
    };
    fetch_mockups();
  }, []);


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };


  return (
    <header style={{ display: 'flex', alignItems: 'center', background: theme.bg2 }}>
      <Link
        style={{ marginRight: 25, marginLeft: 25, fontWeight: 600, fontSize: '1.5em', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        to="/"
        onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Home' })}
      >
        <img src={logo} style={{width: 50, height: 50, marginRight: 15}} />
        <Trademark text="Gateway Forge" size={0.5} />
      </Link>
      {app.isMobile ? (
        <div style={{ marginRight: 25, position: 'relative' }}>
          <button onClick={toggleDropdown} style={{ fontSize: '1.5em', background: 'none', border: 'none', cursor: 'pointer', color: theme.txt }}>
            ☰
          </button>
          {dropdownOpen && (
            <div style={{ position: 'absolute', top: '100%', right: 0, background: theme.bg2, boxShadow: '0 8px 16px rgba(0,0,0,0.2)', zIndex: 1 }}>
              <NavOptions isMobile={app.isMobile} setDropdownOpen={setDropdownOpen} />
            </div>
          )}
        </div>
      ) : (
        <Row style={{ marginRight: 25 }}>
          <NavOptions isMobile={app.isMobile} setDropdownOpen={setDropdownOpen} />
        </Row>
      )}
    </header>
  );

}









export default Header