import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaYoutube, FaTwitter, FaReddit } from 'react-icons/fa';
import { useGlobal } from '../utility/CTX';
import { Column, Row, Trademark } from './components/dom';
import { cloud } from '../utility/firebase';
import { getDownloadURL, ref } from 'firebase/storage';






function Footer() {
  const { app, theme, dispatch } = useGlobal();
  const [logo, setLogo] = useState('');

  useEffect(() => {
    const fetch_mockups = async () => {
      try {      
        const storageRef = ref(cloud, 'site/assets/images/logo_transparent.png');
        const url = await getDownloadURL(storageRef);
        setLogo(url);
      } catch (error) {
        console.log(error);
      }
    };
    fetch_mockups();
  }, []);

  return (
    <footer style={{ display: 'flex', flexDirection: app.isMobile ? 'column' : 'row', flexWrap: 'wrap', justifyContent: 'space-between', background: theme.bg2, padding: 20 }}>

      
      <Row style={{ width: '35%', alignItems: 'center', marginBottom: 20 }}>
        <img src={logo} style={{ width: 130, height: 130 }} alt="Gateway Forge Logo" />
        <Column style={{ marginLeft: 10 }}>
          <h2 style={{ color: theme.orange, margin: '5px 0px' }}><Trademark text='Gateway Forge' size={0.8} /></h2>
          <div>An interactive sandbox where creators can build and explore immersive worlds.</div>
        </Column>
      </Row>

      <Column style={{ marginBottom: 20 }}>
        <h4>Quick Links</h4>
        <Link to='/' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Home' })}>Home</Link>
        <Link to='/waitlist' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Waitlist' })}>Waitlist</Link>
        <Link to='/about' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'About' })}>About Us</Link>
        <Link to='/contact' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Contact' })}>Contact</Link>
      </Column>

      <Column style={{ marginBottom: 20 }}>
        <h4>Legal</h4>
        <Link to='/terms' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Terms' })}>Terms of Use</Link>
        <Link to='/privacy' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Privacy' })}>Privacy Policy</Link>
      </Column>

      <Column style={{ fontSize: '1.5em', marginBottom: 20 }}>
        <SocialButton url='https://www.reddit.com/user/GatewayForge/'>
          <FaReddit style={{ marginBottom: 5 }} />
        </SocialButton>
        <SocialButton url='https://www.youtube.com/@GatewayForge'>
          <FaYoutube style={{ marginBottom: 5 }} />
        </SocialButton> 
        <SocialButton url='https://x.com/GatewayForge'>
          <FaTwitter style={{ marginBottom: 5 }} />
        </SocialButton>  
        <SocialButton url=''>
          <FaFacebook style={{ marginBottom: 5 }} />
        </SocialButton>
      </Column>
    </footer>
  );
}

const SocialButton = ({ url, children }) => {
  return <a href={url} target='_blank' rel='noopener noreferrer'>{children}</a>;
}

export default Footer;
