import React from 'react'
import { Column } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX';
import { Helmet } from 'react-helmet';



function TermsAndConditions() {
    const { app, theme } = useGlobal();


    return (
      <Column style={{ margin: app.isMobile ? '50px 10px 0px 10px' : '50px 200px 0px 200px', paddingBottom: 100, lineHeight: '1.6' }}>
        <Helmet>
            <title>Terms - Gateway Forge</title>
            <meta name="description" content="Terms and conditions." />

            <meta property="og:url" content="https://gatewayforge.com/terms" />
            <meta property="og:title" content="Terms - Gateway Forge" />
            <meta property="og:description" content="Terms and conditions." />

        </Helmet>

        <h2>Terms and Conditions</h2>
        <div>Effective Date: July 10, 2024</div>
  
        <h3>Introduction</h3>
        <p>Welcome to Gateway Forge ("we", "our", "us"). These Terms and Conditions ("Terms") govern your use of our website <a href='https://gatewayforge.com'>gatewayforge.com</a> (the "Site") and the services we offer. By accessing or using our Site, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use our Site.</p>
  
        <h3>Use of the Site</h3>
        <p>You agree to use the Site for lawful purposes only and in a manner that does not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the Site. Prohibited behavior includes harassing or causing distress or inconvenience to any other user, transmitting obscene or offensive content, or disrupting the normal flow of dialogue within the Site.</p>
  
        <h3>Intellectual Property</h3>
        <p>All content on the Site, including text, graphics, logos, images, and software, is the property of Gateway Forge or its content suppliers and is protected by international copyright laws. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Site without express written permission from us.</p>
  
        <h3>User Content</h3>
        <p>You retain ownership of any content you submit, post, or display on or through the Site ("User Content"). By submitting User Content, you grant us a worldwide, non-exclusive, royalty-free license to use, copy, modify, and distribute your User Content in connection with the operation of the Site and our business.</p>
  
        <h3>Limitation of Liability</h3>
        <p>In no event shall Gateway Forge, its directors, employees, or agents be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Site; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Site; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Site by any third party; or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Site, whether based on warranty, contract, tort, or any other legal theory, and whether or not we have been informed of the possibility of such damage.</p>
  
        <h3>Indemnification</h3>
        <p>You agree to indemnify, defend, and hold harmless Gateway Forge, its directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of the Site or your violation of these Terms.</p>
  
        <h3>Governing Law</h3>
        <p>These Terms shall be governed by and construed in accordance with the laws of the State of Colorado, without regard to its conflict of law principles. You agree to submit to the personal jurisdiction of the courts located in Colorado and California for the resolution of any disputes.</p>
  
        <h3>Changes to These Terms</h3>
        <p>We reserve the right to modify or replace these Terms at any time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Site after any changes take effect will constitute your acknowledgment and acceptance of the changes.</p>
  
        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about these Terms, please contact us at:</p>
        <p><strong>Gateway Forge</strong><br />mike@gatewayforge.com</p>
      </Column>
    );
  }



export default TermsAndConditions