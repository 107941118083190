import React, { useState, createContext, useContext, useEffect } from 'react';
import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';

import { auth, db } from './firebase.js';
import {doc, getDoc} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { initializeSession } from './External.js';








async function Authenticate (email, password, setSession) {
    try {
        const credentials = await signInWithEmailAndPassword(auth, email, password);
        return credentials;
    } catch (error) {
        console.error('Error authenticating account:', error);
        return null;
    }
}


async function Register (email, password, setSession) {
    try {
        const credentials = await createUserWithEmailAndPassword(auth, email, password);
        return credentials;

    } catch (error) {
        console.error('Error creating account:', error);
        return null;
    }
}





export const useAuthSession = () => {
    const [session, setSession] = useState(null);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const session = await initializeSession(user);
                setSession(session);
            } else {
                setSession(null);
            }
        });

        return () => unsubscribe();
    }, []);

    return session;
}


export const SessionContext = createContext(null);
export const useSession = () => useContext(SessionContext);











function Auth() {
    const [option, setOption] = useState('SignUp');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const session = useSession();
    const navigate = useNavigate();
    




  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 500, fontSize: '1.2em'}}>
        <div style={{padding: '20px 0px', display: 'flex', flexDirection: 'column', alignItems: 'center', border: '1px solid #232323', borderRadius: 10, width: 300}}>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-evenly', marginBottom: 10}}>
                <div className='transition' style={{cursor: 'pointer', padding: '2px 10px', background: option==='SignIn' && '#939393', color: option==='SignIn' && '#131313', borderRadius: 5}} onClick={(e) => setOption('SignIn')}>Sign In</div>
                <div className='transition' style={{cursor: 'pointer', padding: '2px 10px', background: option==='SignUp' && '#939393', color: option==='SignUp' && '#131313', borderRadius: 5}} onClick={(e) => setOption('SignUp')}>Sign Up</div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: 20}}>
                    <div style={{color: '#828282', fontStyle: 'italic'}}>Email</div>
                    <input style={{width: '80%', padding: '2px 5px', fontSize: '1.1em', borderRadius: 5, fontWeight: 300}} type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>

                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginBottom: 20}}>
                    <div style={{color: '#828282', fontStyle: 'italic'}}>Password</div>
                    <input style={{width: '80%', padding: '2px 5px', fontSize: '1.1em', borderRadius: 5}} type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </div>
            </div>
            
            <div>
                <div className='transition hover-btn' style={{cursor: 'pointer', padding: '2px 10px', color: '#131313', borderRadius: 5}} onClick={(e) => {
                    if (option === 'SignUp') {
                        // create a new account
                        const success = Register(email, password);
                        if (success)    navigate('/');
                    } else {
                        // authenticate an existing account
                        const success = Authenticate(email, password);
                        if (success)    navigate('/');
                    }
                }}>
                    {option === 'SignUp'
                        ? 'Register'
                        : 'Authenticate'
                    }
                </div>
            </div>
        </div>



    </div>
  )
}









export default Auth