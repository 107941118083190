import { useGlobal } from "../../utility/CTX"

export function TextInput({label, value, setValue, style, ...props}) {
    const { theme } = useGlobal();

    return (
        <div style={{marginTop: 5, marginBottom: 5, marginRight: 30, marginLeft: 5, fontSize: '1.2em'}}>
            <div style={{fontWeight: 100, fontStyle: 'italic', color: theme.txt}}>{label}</div>
            <input type='text' value={value} onChange={(e) => setValue(e.target.value)} style={{background: theme.bg2, color: theme.txt, outline: 'none', border: `1px solid ${theme.border}`, ...style}} {...props} />
        </div>
    )
}


export function EmailInput({value, setValue, style, ...props}) {
    const { theme } = useGlobal();

    return (
        <div style={{marginTop: 5, marginBottom: 5, marginRight: 30, marginLeft: 5, fontSize: '1.2em'}}>
            <div style={{fontWeight: 100, fontStyle: 'italic', color: theme.txt}}>Email</div>
            <input type='email' value={value} onChange={(e) => setValue(e.target.value)} style={{background: theme.bg2, color: theme.txt, outline: 'none', border: `1px solid ${theme.border}`, ...style}} {...props} />
        </div>
    )
}



export function TextAreaInput({label, value, setValue, style, ...props}) {
    const { theme } = useGlobal();

    return (
        <div style={{marginTop: 5, marginBottom: 5, marginRight: 30, marginLeft: 5, fontSize: '1.2em'}}>
            <div style={{fontWeight: 100, fontStyle: 'italic', color: theme.txt}}>{label}</div>
            <textarea value={value} onChange={(e) => setValue(e.target.value)} style={{background: theme.bg2, outline: 'none', color: theme.txt, border: `1px solid ${theme.border}`, ...style}} {...props} />
        </div>
    )
}