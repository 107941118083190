import React from 'react';
import { ButtonPill, HrefPill } from './button';




export function Trademark ({text, size=1}) {

  if (text) {
      return (
          <div style={{textWrap: 'nowrap'}}>{text}<sup style={{fontSize: `${size}em`}}>&#8482;</sup></div>
      )
  }
return (
      <sup style={{fontSize: `${size}em`}}>&#8482;</sup>
)
}


export function Section({img, title, description, link, link2, orientation, background, buttonText, buttonText2}) {
    return (
      <div style={{borderRadius: 10, overflow: 'hidden', display: 'flex', background: '#232323', flexDirection: orientation === 'left' ? 'row' : 'row-reverse', width: '100%', marginBottom: 40, boxShadow: '0px 5px 10px #000000'}}>
          <div style={{width: 300, height: '100%', padding: '0px 20px 20px 20px', color: '#DFDFDF', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <h3 style={{margin: '20px 0px 5px 0px', width: '100%'}}>{title}</h3>
              <div style={{textAlign: 'left', fontSize: '0.95em'}}>{description}</div>
              
              <div style={{display: 'flex', flexDirection: 'column', width: 180, justifyContent: 'center', marginTop: 15}}>
                  <HrefPill href={link} newTab={true} text={buttonText}
                    style={{display: 'flex', justifyContent: 'center', padding: '5px 15px', borderRadius: 25, cursor: 'pointer', background: '#DFDFDF', color: background, fontWeight: 600, fontStyle: 'italic'}} />
                  
                  {buttonText2 && 
                    <HrefPill href={link2} newTab={true} text={buttonText2}
                        style={{display: 'flex', marginTop: 10, justifyContent: 'center', padding: '5px 15px', borderRadius: 25, cursor: 'pointer', background: '#DFDFDF', color: background, fontWeight: 600, fontStyle: 'italic'}} />}
              </div>
          </div>
  
          <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
              <div style={{
                  backgroundImage: `url(${img})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  width: '100%',
                  height: 250
              }}></div>
          </div>
  
          
  
  
      </div>
    )
  }





export const Column = ({children, style, ...props}) => {
  return <div style={{display: 'flex', flexDirection: 'column', ...style}} {...props}>
    {children}
  </div>
};





export const Row = ({children, style, ...props}) => {
  return <div style={{display: 'flex', flexDirection: 'row', ...style}} {...props}>
    {children}
  </div>
}