import React, { useContext, useState, useEffect } from 'react';
import { EmailInput, TextAreaInput, TextInput } from '../../layout/components/input';
import { Link } from 'react-router-dom';
import { GlobalContext, useGlobal } from '../../utility/CTX';
import {httpsCallable} from 'firebase/functions';
import { cloud } from '../../utility/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { Column } from '../../layout/components/dom';
import { Helmet } from 'react-helmet';








function Contact() {
  const { app, theme, dispatch } = useGlobal();

  const {ctx, setCtx} = useContext(GlobalContext);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const [imgPath, setImgPath] = useState('');


  useEffect(() => {
      const fetch_mockups = async () => {
          try {      
              const storageRef = ref(cloud, 'site/assets/images/coverImage.png');
              const url = await getDownloadURL(storageRef);
              setImgPath(url);
          } catch (error) {
              console.log(error);
          }
      };
      fetch_mockups();
  }, []);

  const sendEmail = () => {
    const emailFunction = httpsCallable(cloud, 'primaryContact');
    emailFunction({
      to: email,
      subject,
      text: `Name: ${name}\n${message}`
    }).then((res) => {
      if (res.data.success) {
        console.log('Email sent successfully');
      } else {
        console.log('Error sending email: ', res);
      }
    }).catch((err) => {
      console.log('Error calling primaryContact function: ', err);
    })
  };



  return <div>
    <Helmet>
      <title>Contact - Gateway Forge</title>
      <meta name="description" content="Get in touch." />

      <meta property="og:url" content="https://gatewayforge.com/contact" />
      <meta property="og:title" content="Contact - Gateway Forge" />
      <meta property="og:description" content="Get in touch." />

    </Helmet>
    
    <Column style={{position: 'relative', overflow: 'hidden', height: 250}}>
        <img src={imgPath} style={{position: 'absolute', top: app.isMobile ? 0 : -100, width: '100%'}} />
    </Column>

    <div style={{margin: !app.isMobile && '0px 200px'}}>
      <h2>Contact Us</h2>
      <p>We'd love to hear from you!</p>
      <p>Have a question, idea, or just want to say hello? Reach out to us using the form below, and we'll get back to you as soon as possible.</p>
    </div>

    <div style={{margin: '20px 200px', display: 'flex', flexDirection: 'row'}}>
      <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column', borderRight: '1px solid', borderColor: theme.border}}>
        <TextInput label='Name' value={name} setValue={setName} style={{
          borderRadius: 5,
          padding: '2px 5px',
          fontSize: '0.9em',
          width: '100%'
        }}/>
        <EmailInput value={email} setValue={setEmail} style={{
          borderRadius: 5,
          padding: '2px 5px',
          fontSize: '0.9em',
          width: '100%'
        }}/>
        <TextInput label='Subject' value={subject} setValue={setSubject} style={{
          borderRadius: 5,
          padding: '2px 5px',
          fontSize: '0.9em',
          width: '100%'
        }}/>
        <TextAreaInput label='Message' value={message} setValue={setMessage} rows={6} style={{
          width: '100%',
          borderRadius: 5,
          padding: '5px',
          fontSize: '1em'
        }} />

        <SubmitButton sendEmail={sendEmail} />
      </div>

      <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column', maxWidth: '50%', justifyContent: 'space-evenly'}}>
        <div style={{margin: '0px 15px'}}>
          <i>Join our waitlist to stay updated on development progress!</i>
          <Link style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 5,
            borderRadius: 5,
            marginLeft: '70%',
            textAlign: 'center',
            color: theme.orange,
            fontWeight: 400,
            cursor: 'pointer'
          }} to='/waitlist' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'Waitlist'})}>Waitlist <span className='material-symbols-rounded'>chevron_right</span></Link>
        </div>


        <div style={{margin: '0px 15px'}}>
          <i>Learn more about our company and who we are.</i>
          <Link style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 5,
            borderRadius: 5,
            marginLeft: '70%',
            textAlign: 'center',
            color: theme.orange,
            fontWeight: 400,
            cursor: 'pointer'
          }} to='/about' onClick={(e) => dispatch({ type: 'SET_PAGE', page: 'About'})}>About <span className='material-symbols-rounded'>chevron_right</span></Link>
        </div>
      </div>
    </div>
    <div style={{height: 100}}>

    </div>
  </div>
};



const SubmitButton = ({sendEmail}) => {
  const { theme } = useGlobal();

  return <div style={{
    padding: 5,
    borderRadius: 15,
    background: theme.orange,
    width: 75,
    margin: '20px auto',
    textAlign: 'center',
    color: theme.bg,
    fontWeight: 500,
    cursor: 'pointer'
  }}
  onClick={sendEmail}>Submit</div>
}

export default Contact