
















export const selectTheme = (value) => {
    switch (value) {

        default:    return DefaultTheme;
    }
};



const DefaultTheme = {
    bg: '#010101', // Almost black
    bg1: '#121212', // Darker grey
    bg2: '#1e1e1e', // Dark grey
    bg3: '#2e2e2e', // Medium dark grey
    bg4: '#383838', // Lighter dark grey
    bg5: '#424242', // Lightest dark grey
  
    txt: '#e0e0e0', // Light grey for main text
    txt_alt: '#b0b0b0', // Slightly darker grey for alternative text
    txt_dark: '#888888', // Dark grey for less prominent text
    txt_opp: '#ffffff', // White for high contrast text
  
    border: '#3c3c3c', // Border color
    border_alt: '#525252', // Alternative border color
  
    red: '#e43223',    // Brighter red
    orange: '#F0732C', // Brighter orange
    yellow: '#ffe680', // Brighter yellow
    green: '#49df32',  // Brighter green
    blue: '#159ae7',   // Brighter blue
    indigo: '#9aa2eb', // Brighter indigo
    violet: '#b844f0', // Brighter violet
    pink: '#ff91d9',   // Brighter pink
    teal: '#05d9d2',   // Brighter teal
  
    black: '#000000', // Black
    white: '#ffffff', // White
    brown: '#8d6e63', // Soft brown
  
    error: '#e57373', // Error state color
    success: '#81c784', // Success state color
    warning: '#ffb74d', // Warning state color
    info: '#64b5f6', // Info state color
  };
  