import React, { useState, useEffect} from 'react'
import { Column, Row } from '../../layout/components/dom'
import { getDocs, collection, query, limit, orderBy, where} from 'firebase/firestore';
import { db } from '../../utility/firebase';
import { Article } from '../../classes/Article';
import { useGlobal } from '../../utility/CTX';
import ArticleCard from './ArticleCard';







function Blog() {
  const { app, theme } = useGlobal();
  const [articles, setArticles] = useState([])

  // this is the landing page for the blog...
  // show featured, popular, etc.
  const getDocuments = async () => {
    const collectionRef = collection(db, 'blog');
    const q = query(collectionRef, limit(50));
    const snapshot = await getDocs(q);
    const documents = snapshot.docs.map(doc => {
      return new Article({id: doc.id, ...doc.data()});
    });
    setArticles(documents);
  };
  

  useEffect(() => {
    getDocuments();
  }, []);

  return <Column>

    <Row style={{flexWrap: app.isMobile && 'wrap'}}>
      <Column style={{flexGrow: 1, overflow: 'hidden'}}>
        <FeaturedArticles />
        <RecentArticles />

      </Column>

      <PressReleaseContainer />
    </Row>
  </Column>
};




const PressReleaseContainer = () => {
  const { theme } = useGlobal();

  return <Column style={{borderLeft: `1px solid ${theme.border}`, marginLeft: 10, paddingLeft: 10, flexGrow: 1}}>
    <Row>Press Releases</Row>
  </Column>
};



const FeaturedArticles = () => {
  const { app, theme } = useGlobal();
  const [articles, setArticles] = useState([]);

  const getFeaturedDocuments = async () => {
    const collectionRef = collection(db, 'blog');
    const q = query(collectionRef, where('featured', '==', true), limit(5));
    const snapshot = await getDocs(q);
    const documents = snapshot.docs.map(doc => {
      return new Article({ id: doc.id, ...doc.data() });
    });
    setArticles(documents);
  };

  useEffect(() => {
    getFeaturedDocuments()
  }, []);




  return <Column style={{overflow: 'hidden'}}>
    <Row style={{justifyContent: app.isMobile && 'center', fontSize: '1.2em', fontWeight: 'bold', margin: 10}}>Featured Articles</Row>
    <Row style={{overflowX: 'auto', overflowY: 'hidden', flexWrap: app.isMobile && 'wrap', justifyContent: app.isMobile && 'center'}}>

      {articles.map((article, i) => <ArticleCard key={article.id+'-featured-articles-'+i} article={article} />)}

    </Row>
  </Column>
}




const RecentArticles = () => {
  const { app, theme } = useGlobal();
  const [articles, setArticles] = useState([]);

  const getMostRecentDocuments = async () => {
    const collectionRef = collection(db, 'blog');
    const q = query(collectionRef, orderBy('updatedAt', 'desc'), limit(5));
    const snapshot = await getDocs(q);
    const documents = snapshot.docs.map(doc => {
      return new Article({ id: doc.id, ...doc.data() });
    });
    setArticles(documents);
  };

  useEffect(() => {
    getMostRecentDocuments()
  }, []);




  return <Column style={{overflow: 'hidden'}}>
    <Row style={{justifyContent: app.isMobile && 'center', fontSize: '1.2em', fontWeight: 'bold', margin: 10}}>Recent Articles</Row>
    <Row style={{overflowX: 'auto', overflowY: 'hidden', flexWrap: app.isMobile && 'wrap', justifyContent: app.isMobile && 'center'}}>

      {articles.map((article, i) => <ArticleCard key={article.id+'-recent-articles-'+i} article={article} />)}

    </Row>
  </Column>
}

















export default Blog