import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { LinkPill } from '../../layout/components/button';
import { useGlobal } from '../../utility/CTX';
import { Column, Row } from '../../layout/components/dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { cloud } from '../../utility/firebase';
import { Helmet } from 'react-helmet';

function About() {
  const { app, theme } = useGlobal();
  const [imgPath, setImgPath] = useState('');

  useEffect(() => {
    const fetch_mockups = async () => {
      try {      
        const storageRef = ref(cloud, 'site/assets/images/timeline.png');
        const url = await getDownloadURL(storageRef);
        setImgPath(url);
      } catch (error) {
        console.log(error);
      }
    };
    fetch_mockups();
}, []);



  return (
    <div style={{}}>
      <Helmet>
        <title>About - Gateway Forge</title>
        <meta name="description" content="Get to know the team behind the project." />

        <meta property="og:url" content="https://gatewayforge.com/about" />
        <meta property="og:title" content="About - Gateway Forge" />
        <meta property="og:description" content="Get to know the team behind the project." />

      </Helmet>

      <Column style={{position: 'relative', overflow: 'hidden', height: 250}}>
          <img src={imgPath} style={{position: 'absolute', top: app.isMobile ? 0 : -100, width: '100%'}} />
      </Column>

      <div style={{margin: !app.isMobile && '0px 200px'}}>
        <h2>About Us</h2>
        <p>What began as a simple idea for a video game has blossomed into a robust worldbuilding platform. This application represents years of dedication and passion, evolving from its initial concept into a powerful tool that empowers creators to bring their universes to life.</p>
        <p>Designed to work offline, it provides you with the freedom to develop your worlds without needing an internet connection. Seamlessly integrate and utilize documents you have already written, making it effortless to organize and expand upon your creative ideas.</p>
        <p>Entirely self-funded and driven by a small, dedicated team of passionate creators, this project is fueled by our love for storytelling and worldbuilding. We are devoted to enhancing your creative process, and helping you craft detailed and immersive universes.</p>
        <p>Thank you for joining us on this journey. We are excited to see the incredible worlds you will create with our platform.</p>
      </div>


      <Row  style={{margin: app.isMobile ? '50px 10px 0px 10px' : '50px 250px 0px 250px', borderTop: '1px solid', borderColor: theme.border, padding: 20, justifyContent: 'center'}}>
        <WaitlistButton />
        <div style={{width: 20}} />
        <GetInTouchButton />
      </Row>
    </div>
  )
};




const WaitlistButton = () => {
  const { theme, dispatch } = useGlobal();
  const [hover, setHover] = useState(false);


  return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <LinkPill to='/waitlist' text='Join the Waitlist' onClick={() => dispatch({ type: 'SET_PAGE', page: 'Waitlist'})} 
          style={{transition: 'background 0.2s ease, color 0.2s ease', border: `1px solid ${theme.blue}`, color: hover ? theme.bg2 : theme.blue, background: hover && theme.blue, borderRadius: 50, padding: '0px 5px 0px 15px'}} />
  </div>
}



const GetInTouchButton = () => {
  const { theme, dispatch } = useGlobal();
  const [hover, setHover] = useState(false);


  return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <LinkPill to='/contact' text='Get In Touch' onClick={() => dispatch({ type: 'SET_PAGE', page: 'Contact'})} 
          style={{transition: 'background 0.2s ease, color 0.2s ease', border: `1px solid ${theme.orange}`, color: hover ? theme.bg2 : theme.orange, background: hover && theme.orange, borderRadius: 50, padding: '0px 5px 0px 15px'}} />
  </div>
}






export default About