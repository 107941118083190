import { appReducer, initialAppState } from "./appReducer";
import { initialThemeState, themeReducer } from "./themeReducer";







export const initialState = {
    app: initialAppState,
    theme: initialThemeState,

};



export const reducers = {
    app: appReducer,
    theme: themeReducer,

}