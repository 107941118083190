import React, { useCallback, useState } from 'react'
import { useGlobal } from '../../utility/CTX'
import { Column, Row } from '../../layout/components/dom';
import { EmailInput, NumberInput, OptionsInput, TextInput } from './Inputs';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../utility/firebase';

function InfoForm({form, setForm, testerSurvey, setTesterSurvey}) {
    const { app, theme } = useGlobal();

    const [submitted, setSubmitted] = useState(false);
    const [cannotSubmit, setCannotSubmit] = useState(false);

    const toggleOption = useCallback((key, value) => {
        setForm(prev => ({...prev, [key]: prev[key].includes(value) ? prev[key].filter(i => i !== value) : [...prev[key], value]}))
    }, [setForm]);

    const det = useCallback((key, value) => form[key].includes(value), [form]);

    return <Column style={{margin: app.isMobile ? '0px 10px' : '50px 200px'}}>
        <h2 style={{margin: '0px 0px 50px 0px', textAlign: 'center'}}>Details</h2>


        <Row style={{justifyContent: 'space-evenly', flexWrap: 'wrap'}}>
            <TextInput label='Name' setValue={(name) => setForm(prev => ({...prev, name }))} />
            <EmailInput label='Email' setValue={(email) => setForm(prev => ({...prev, email }))} />
            <NumberInput label='Age' setValue={(age) => setForm(prev => ({...prev, age }))} style={{width: 60}} />
            <Row style={{alignItems: 'center'}}>
                <Row style={{marginRight: 5}}>Gender</Row>
                <Row style={{background: form.gender === 'male' ? theme.bg4 : theme.bg2, color: form.gender === 'male' ? theme.green : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                    onClick={() => setForm(prev => ({...prev, gender: 'male'}))}>Male</Row>
                <Row style={{background: form.gender === 'female' ? theme.bg4 : theme.bg2, color: form.gender === 'female' ? theme.green : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                    onClick={() => setForm(prev => ({...prev, gender: 'female'}))}>Female</Row>
                <Row style={{background: form.gender === 'other' ? theme.bg4 : theme.bg2, color: form.gender === 'other' ? theme.green : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                    onClick={() => setForm(prev => ({...prev, gender: 'other'}))}>Other</Row>
            </Row>
        </Row>
        

        <div style={{height: 20}} />

        <OptionsInput label='Which of these best describes you?'>
            <Row style={{background: det('descriptors', 'writer') ? theme.bg4 : theme.bg2, color: det('descriptors', 'writer') ? theme.blue : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('descriptors', 'writer')}>Writer</Row>
            <Row style={{background: det('descriptors', 'artist') ? theme.bg4 : theme.bg2, color: det('descriptors', 'artist') ? theme.blue : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('descriptors', 'artist')}>Artist</Row>
            <Row style={{background: det('descriptors', 'filmmaker') ? theme.bg4 : theme.bg2, color: det('descriptors', 'filmmaker') ? theme.blue : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('descriptors', 'filmmaker')}>Filmmaker</Row>
            <Row style={{background: det('descriptors', 'developer') ? theme.bg4 : theme.bg2, color: det('descriptors', 'developer') ? theme.blue : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('descriptors', 'developer')}>Developer</Row>
            <Row style={{background: det('descriptors', 'dungeon master') ? theme.bg4 : theme.bg2, color: det('descriptors', 'dungeon master') ? theme.blue : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('descriptors', 'dungeon master')}>Dungeon Master</Row>
            <Row style={{background: det('descriptors', 'hobbiest') ? theme.bg4 : theme.bg2, color: det('descriptors', 'hobbiest') ? theme.blue : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('descriptors', 'hobbiest')}>Hobbiest</Row>
            <Row style={{background: det('descriptors', 'teacher') ? theme.bg4 : theme.bg2, color: det('descriptors', 'teacher') ? theme.blue : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('descriptors', 'teacher')}>Teacher</Row>
            <Row style={{background: det('descriptors', 'historian') ? theme.bg4 : theme.bg2, color: det('descriptors', 'historian') ? theme.blue : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('descriptors', 'historian')}>Historian</Row>
            
        </OptionsInput>


        <Row style={{justifyContent: 'center', padding: 40}}>
            <Column>
                {!submitted && cannotSubmit && <Row style={{color: theme.red, margin: 5}}>Please fill out all of the options</Row>}
                {!submitted && <SubmitButton form={form} onToggleSubmit={() => setSubmitted(true)} onCannotSubmit={() => setCannotSubmit(true)}/>}
            </Column>
            
            {submitted && <Row style={{color: theme.green}}>Thank you for joining our waitlist!</Row>}
        </Row>
    
    </Column>
};




const Btn = ({label, color, onClick}) => {
    const { theme, dispatch } = useGlobal();
    const [hover, setHover] = useState(false);

    return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={onClick} 
        style={{transition: 'background 0.2s ease, color 0.2s ease', border: `1px solid ${color}`, cursor: 'pointer', color: hover ? theme.bg2 : color, background: hover && color, borderRadius: 50, padding: '5px 15px'}}>
        {label}
    </div>
}




const SubmitButton = ({form, onToggleSubmit=()=>{}, onCannotSubmit=()=>{}}) => {
    const { theme, dispatch } = useGlobal();
    const [hover, setHover] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (form.email && form.name && form.gender && form.descriptors.length) {
                await addDoc(collection(db, 'waitlist'), form);
                onToggleSubmit();
            } else {
                onCannotSubmit();
            }
        } catch (error) {
            console.log(error);
        }
    };

    return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handleSubmit}
        style={{textAlign: 'center', transition: 'background 0.2s ease, color 0.2s ease', border: `1px solid ${theme.blue}`, cursor: 'pointer', color: hover ? theme.bg2 : theme.blue, background: hover && theme.blue, borderRadius: 50, padding: '5px 15px'}}>
        Join the Waitlist
    </div>
}




export default InfoForm