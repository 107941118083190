import React, { useState, useEffect } from 'react'
import { Column, Row } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX'
import { getDownloadURL, ref } from 'firebase/storage';
import { cloud } from '../../utility/firebase';
import { EmailInput, NumberInput, OptionsInput, TextAreaInput, TextInput } from './Inputs';
import InfoForm from './InfoForm';
import Survey from './Survey';
import { Helmet } from 'react-helmet';

function Waitlist() {
    const { theme, app } = useGlobal();
    const [imgPath, setImgPath] = useState('');


    useEffect(() => {
        const fetch_mockups = async () => {
            try {      
                const storageRef = ref(cloud, 'site/assets/images/publishing.png');
                const url = await getDownloadURL(storageRef);
                setImgPath(url);
            } catch (error) {
                console.log(error);
            }
        };
        fetch_mockups();
    }, []);


    


    const [form, setForm] = useState({
        name: '',
        email: '',
        age: 0,
        gender: '',
        descriptors: [],
        otherDescriptors: []
    });
    const [testerSurvey, setTesterSurvey] = useState(false);



    return <Column style={{justifyContent: 'center', flexGrow: 1}}>
        <Helmet>
            <title>Waitlist - Gateway Forge</title>
            <meta name="description" content="Join the waitlist to recieve updates on developments." />

            <meta property="og:url" content="https://gatewayforge.com/waitlist" />
            <meta property="og:title" content="Waitlist - Gateway Forge" />
            <meta property="og:description" content="Join the waitlist to recieve updates on developments." />

        </Helmet>

        {/** introduction to the waitlist and survey */}
        <Column style={{position: 'relative', overflow: 'hidden', height: 250}}>
            <img src={imgPath} style={{position: 'absolute', top: app.isMobile ? 0 : -100, width: '100%'}} />
        </Column>

        {/** basic information form */}

        <InfoForm form={form} setForm={setForm} setTesterSurvey={setTesterSurvey} testerSurvey={testerSurvey} />

        <div style={{borderBottom: `1px solid ${theme.border}`, margin: app.isMobile ? '0px 30px' : '0px 150px'}} />

        <Survey form={form} />


    </Column>
};







export default Waitlist