




export const initialAppState = {
    page: 'Home',
    mockupUrls: [],
    isMobile: true
};



export const appReducer = ( state = initialAppState, action) => {
    switch (action.type) {
        case 'SET_PAGE': {
            return { ...state, page: action.page };
        };
        case 'SET_MOCKUP_URLS': {
            return { ...state, mockupUrls: action.urls };
        };
        case 'SET_IS_MOBILE': {
            return { ...state, isMobile: action.value };
        };
        default:        return state;
    }
}