
import React, { useCallback, useState } from 'react'
import { Column, Row } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX'
import { useNavigate } from 'react-router-dom';





function ArticleCard({article}) {
    const { theme } = useGlobal();
    const [hover, setHover] = useState(false);
    const navigate = useNavigate();

    const handleSelectArticle = useCallback(() => {
        navigate(`/blog/${article.slug}`);
    }, [navigate, article])
        
    return <Column style={{background: hover ? theme.bg3 : theme.bg2, margin: 5, borderRadius: 4, width: 250, maxWidth: 250, minWidth: 250, fontSize: '0.9em', overflow: 'hidden', justifyContent: 'space-between', cursor: 'pointer'}}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={handleSelectArticle}>
        <Row style={{padding: '5px 10px'}}>{article.title}</Row>
        <img src={article?.cover} />
    </Column>
};



export default ArticleCard