import React from 'react'
import { Column, Row } from '../../layout/components/dom'
import { useParams } from 'react-router-dom'
import { useState } from 'react';
import { db } from '../../utility/firebase';
import { useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { makeHeaderId, SectionComponent } from './BlogPostSections';
import { useGlobal } from '../../utility/CTX';






function BlogPost() {
    const { app } = useGlobal();
    const { slug } = useParams();
    const [article, setArticle] = useState();


    const fetchPost = async (slug) => {
        try {
            console.log('fetching article: ', slug)
            const q = query(collection(db, 'blog'), where('slug', '==', slug));
            const snapshot = await getDocs(q);
            if (!snapshot.empty) {
                const doc = snapshot.docs[0];
                setArticle(doc.data());
                console.log('loaded slug:', slug);
            };
        } catch (error) {
            console.log('could not load document slug: ', slug);
            console.error(error);
        };
    }


    useEffect(() => {
        fetchPost(slug);
    }, [slug])

    return <Row style={{flexGrow: 1, justifyContent: 'center'}}>
        <Column style={{width: !app.isMobile && '70%', alignItems: 'center', margin: '50px 0px'}}>
            <ArticleHeader article={article} />
            <ArticleTableOfContents article={article} />
            <ArticleContent article={article} />
        </Column>
    </Row>
};




const ArticleHeader = ({article}) => {


    // TODO: Construct a table of contents based on the content
    console.log(article);

    return <Column style={{alignItems: 'center', margin: '20px 0px'}}>
        <h1 style={{}}>{article?.title}</h1>
        {/** post collection at the bottom */}
    </Column>
};





const ArticleTableOfContents = ({article}) => {
    const { theme } = useGlobal();
    const [headers, setHeaders] = useState([]);

    useEffect(() => {
        if (article) {
            let filtered = article.sections.filter(section => section.type === 'header');
            filtered = filtered.map(section => section.content);
            setHeaders(filtered);
        }
    }, [article?.sections]);


    return <Column style={{width: 400, background: theme.bg3, borderRadius: 10, padding: 5, marginBottom: 30}}>
        <ol style={{}}>
            {headers.map(header => (
                <li key={article.id + header} style={{ margin: 10 }}>
                    <a href={makeHeaderId(header)} style={{ color: theme.text }}>
                        {header}
                    </a>
                </li>
            ))}
        </ol>
    
    </Column>
}




const ArticleContent = ({article}) => {
    const { theme, } = useGlobal();

    return <Column>
        <img src={article?.cover} style={{height: 200, objectFit: 'cover', width: '100%', borderRadius: 5, border: `1px solid ${theme.border}`}} />

        <Column style={{marginTop: 20}}>
            {article?.sections.map((section, i) => <SectionComponent key={section.id+'-article-'+i}  section={section} article={article} />)}
        </Column>

        <p />

        <Column style={{color: theme.txt_dark, margin: '10px 100px'}}>
            <div><em>Written By: {article?.author}</em></div>
            <div>Updated: {new Date(article?.updatedAt.seconds * 1000).toLocaleDateString()}</div>

        </Column>
        
        <p />
    
    </Column>
}


















export default BlogPost