import React, { useState } from 'react'
import { useGlobal } from '../../utility/CTX';
import { Column, Row } from '../../layout/components/dom';



export const TextInput = ({label, setValue=()=>{}}) => {
  const { theme } = useGlobal();
  const [val, setVal] = useState('');

  return <Row style={{alignItems: 'center', margin: 5}}>
    <div>{label}</div>
    <input type='text' value={val} onChange={(e) => setVal(e.target.value)} onBlur={() => setValue(val)} 
      style={{padding: '5px 10px', textAlign: 'right', marginLeft: 10, outline: 'none', border: `1px solid ${theme.border}`, borderRadius: 4, background: theme.bg2, color: theme.txt}} />
  </Row>
};


export const TextAreaInput = ({ label, setValue = () => {} }) => {
  const { theme } = useGlobal();
  const [val, setVal] = useState('');

  return <Column style={{ margin: 5}}>
    <div>{label}</div>
    <textarea value={val} onChange={(e) => setVal(e.target.value)} onBlur={() => setValue(val)} 
      style={{padding: '5px 10px', outline: 'none', border: `1px solid ${theme.border}`, borderRadius: 4, background: theme.bg2, color: theme.txt, margin: 5}} />
  </Column>
};



export const NumberInput = ({ label, style, setValue = () => {} }) => {
  const { theme } = useGlobal();
  const [val, setVal] = useState('');

  return <Row style={{alignItems: 'center', margin: 5}}>
    <div>{label}</div>
    <input type="number" value={val} onChange={(e) => setVal(e.target.value)} onBlur={() => setValue(val)} 
      style={{padding: '5px 10px', textAlign: 'right',  marginLeft: 10, outline: 'none', border: `1px solid ${theme.border}`, borderRadius: 4, background: theme.bg2, color: theme.txt, ...style}}/>
  </Row>

};



export const DateInput = ({ label, setValue = () => {} }) => {
  const { theme } = useGlobal();
  const [val, setVal] = useState('');

  return <Row style={{alignItems: 'center', margin: 5}}>
    <div>{label}</div>
    <input type="date" value={val} onChange={(e) => setVal(e.target.value)} onBlur={() => setValue(val)} 
      style={{outline: 'none', border: `1px solid ${theme.border}`, borderRadius: 4, background: theme.bg2, color: theme.txt}}/>
  </Row>
};



export const EmailInput = ({ label, setValue = () => {} }) => {
  const { theme } = useGlobal();
  const [val, setVal] = useState('');

  return <Row style={{alignItems: 'center', margin: 5}}>
    <div>{label}</div>
    <input type="email" value={val} onChange={(e) => setVal(e.target.value)} onBlur={() => setValue(val)} 
      style={{padding: '5px 10px', textAlign: 'right', marginLeft: 10, outline: 'none', border: `1px solid ${theme.border}`, borderRadius: 4, background: theme.bg2, color: theme.txt}}/>
  </Row>
  
};





export const OptionsInput = ({ label, children}) => {
  const { theme } = useGlobal();

  return <Column style={{margin: 5}}>
    <div style={{marginBottom: 5}}>{label}</div>
    <Row style={{color: theme.txt_dark, justifyContent: 'center', flexWrap: 'wrap'}}>{children}</Row>
  </Column>
}