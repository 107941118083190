import React, { useState, useCallback, useEffect } from 'react'
import { useGlobal } from '../../utility/CTX'
import { OptionsInput, TextAreaInput, TextInput } from './Inputs';
import { Column, Row } from '../../layout/components/dom';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../utility/firebase';

function Survey({form}) {
    const { theme, app } = useGlobal();
    const [survey, setSurvey] = useState({
        email: form?.email || '',
        previous: '',
        important: '',
        missing: '',
        otherInfo: '',
        roadblocks: [],
        timesuck: '',
        tools: [],
        wants: ''
    });
    const [cannotSubmit, setCannotSubmit] = useState(false);


    useEffect(() => {
        setSurvey(prev => ({...prev, email: form.email}));
    }, [form.email]);

    const toggleOption = useCallback((key, value) => {
        setSurvey(prev => ({...prev, [key]: prev[key].includes(value) ? prev[key].filter(i => i !== value) : [...prev[key], value]}))
    }, [setSurvey]);

    const det = useCallback((key, value) => survey[key].includes(value), [survey]);


    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (form.email && form.previous && form.important && form.missing && form.roadblocks.length && form.timesuck && form.tools.length && form.wants) {
                await addDoc(collection(db, 'surveys/PreAlphaSurvey01/responses'), survey);
                setSubmitted(true);
            } else {
                setCannotSubmit(true);
            }
        } catch (error) {
            console.log(error);
        }
    };




    return <Column style={{margin: app.isMobile ? '50px 10px' : '50px 200px'}}>
        <h2 style={{textAlign: 'center'}}>Alpha Testing</h2>
        <div style={{color: theme.txt_dark, textAlign: 'center'}}>
            Thank you for your interest in shaping the future of Gateway Forge<sup style={{fontSize: `0.6em`}}>&#8482;</sup>. 
            As an alpha tester, you'll explore our platform before its public release and provide 
            crucial feedback that will be crucial in helping us identify bugs, improve features, 
            and enhance the overall user experience. Please fill out the form below to join our exclusive 
            tester group. While we appreciate all interest, please note that not everyone may be selected 
            to participate.
        </div>

        <div style={{height: 50}} />

        <TextInput label='Have you participated in software testing before?' setValue={(previous) => setSurvey(prev => ({...prev, previous }))} />

        <div style={{height: 20}} />

        <OptionsInput label='Do you currently use any worldbuilding software?'>
            <Row style={{background: det('tools', 'world anvil') ? theme.bg4 : theme.bg2, color: det('tools', 'world anvil') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'world anvil')}>World Anvil</Row>
            <Row style={{background: det('tools', 'legend keeper') ? theme.bg4 : theme.bg2, color: det('tools', 'legend keeper') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'legend keeper')}>Legend Keeper</Row>
            <Row style={{background: det('tools', 'campfire writing') ? theme.bg4 : theme.bg2, color: det('tools', 'campfire writing') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'campfire writing')}>Campfire Writing</Row>
            <Row style={{background: det('tools', 'obsidian') ? theme.bg4 : theme.bg2, color: det('tools', 'obsidian') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'obsidian')}>Obsidian</Row>
            <Row style={{background: det('tools', 'inkarnate') ? theme.bg4 : theme.bg2, color: det('tools', 'inkarnate') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'inkarnate')}>Inkarnate</Row>
            <Row style={{background: det('tools', 'scrivener') ? theme.bg4 : theme.bg2, color: det('tools', 'scrivener') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'scrivener')}>Scrivener</Row>
            <Row style={{background: det('tools', 'evernote') ? theme.bg4 : theme.bg2, color: det('tools', 'evernote') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'evernote')}>Evernote</Row>
            <Row style={{background: det('tools', 'celtx') ? theme.bg4 : theme.bg2, color: det('tools', 'celtx') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'celtx')}>Celtx</Row>
            <Row style={{background: det('tools', 'ulysses') ? theme.bg4 : theme.bg2, color: det('tools', 'ulysses') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'ulysses')}>Ulysses</Row>
            <Row style={{background: det('tools', 'final draft') ? theme.bg4 : theme.bg2, color: det('tools', 'final draft') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'final draft')}>Final Draft</Row>
            <Row style={{background: det('tools', 'fantasia archive') ? theme.bg4 : theme.bg2, color: det('tools', 'fantasia archive') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('tools', 'fantasia archive')}>Fantasia Archive</Row>
        </OptionsInput> 
            
        <div style={{height: 20}} />

        <TextAreaInput 
            label='What is important to your creative process?' 
            setValue={(important) => setSurvey(prev => ({...prev, important }))} 
        />
                    
        <div style={{height: 20}} />

        <TextAreaInput 
            label='What is missing from your creative process?' 
            setValue={(missing) => setSurvey(prev => ({...prev, missing }))} 
        />

        <div style={{height: 20}} />

        <TextAreaInput 
            label='What do you spend the most time on?' 
            setValue={(timesuck) => setSurvey(prev => ({...prev, timesuck }))} 
        />

        <div style={{height: 20}} />

        <OptionsInput label='What are the primary roadblocks you encounter?'>
            <Row style={{background: det('roadblocks', 'procrastination') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'procrastination') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'procrastination')}>Procrastination</Row>
            <Row style={{background: det('roadblocks', 'time management') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'time management') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'time management')}>Time Management</Row>
            <Row style={{background: det('roadblocks', "writer's block") ? theme.bg4 : theme.bg2, color: det('roadblocks', 'writer\'s block') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', "writer's block")}>Writer's Block</Row>
            <Row style={{background: det('roadblocks', 'lack of inspiration') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'lack of inspiration') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'lack of inspiration')}>Lack of Inspiration</Row>
            <Row style={{background: det('roadblocks', 'self-doubt') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'self-doubt') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'self-doubt')}>Self-Doubt</Row>
            <Row style={{background: det('roadblocks', 'editing overload') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'editing overload') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'editing overload')}>Editing Overload</Row>
            <Row style={{background: det('roadblocks', 'plot holes') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'plot holes') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'plot holes')}>Plot Holes</Row>
            <Row style={{background: det('roadblocks', 'character development') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'character development') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'character development')}>Character Development</Row>
            <Row style={{background: det('roadblocks', 'consistency') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'consistency') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'consistency')}>Consistency</Row>
            <Row style={{background: det('roadblocks', 'marketability') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'marketability') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'marketability')}>Marketability</Row>
            <Row style={{background: det('roadblocks', 'feedback and criticism') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'feedback and criticism') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'feedback and criticism')}>Feedback and Criticism</Row>
            <Row style={{background: det('roadblocks', 'burnout') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'burnout') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'burnout')}>Burnout</Row>
            <Row style={{background: det('roadblocks', 'resource constraints') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'resource constraints') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'resource constraints')}>Resource Constraints</Row>
            <Row style={{background: det('roadblocks', 'imposter syndrome') ? theme.bg4 : theme.bg2, color: det('roadblocks', 'imposter syndrome') ? theme.orange : theme.txt_dark, cursor: 'pointer', borderRadius: 4, margin: 5, padding: '5px 15px'}}
                onClick={() => toggleOption('roadblocks', 'imposter syndrome')}>Imposter Syndrome</Row>
            
        </OptionsInput>


        <div style={{height: 20}} />

        <TextAreaInput 
            label='What would you like to see in a worldbuilding application?' 
            setValue={(wants) => setSurvey(prev => ({...prev, wants }))} 
        />

        <div style={{height: 20}} />

        <TextAreaInput 
            label='Is there anything else you want us to know about you?' 
            setValue={(otherInfo) => setSurvey(prev => ({...prev, otherInfo }))} 
        />


        <Row style={{justifyContent: 'center', padding: 40}}>
            <Column>
                {!submitted && cannotSubmit &&  <Row style={{color: theme.red, margin: 5}}>Please fill out all of the questions</Row>}
                {!submitted && <Btn label='Submit' color={theme.orange} onClick={handleSubmit} />}
            </Column>
            {submitted && <Row style={{color: theme.green}}>Success!</Row>}

        </Row>
    
    </Column>
};






const Btn = ({label, color, onClick}) => {
    const { theme, dispatch } = useGlobal();
    const [hover, setHover] = useState(false);

    return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={onClick} 
        style={{textAlign: 'center', transition: 'background 0.2s ease, color 0.2s ease', border: `1px solid ${color}`, cursor: 'pointer', color: hover ? theme.bg2 : color, background: hover && color, borderRadius: 50, padding: '5px 15px'}}>
        {label}
    </div>
};







export default Survey