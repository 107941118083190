import React from 'react';
import { Link } from 'react-router-dom';


export function LinkPill ({to, text, onClick, style}) {
    return (
        <Link to={to} onClick={onClick} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', ...style}}>
            <div>{text}</div>
            <span className='material-symbols-rounded'>chevron_right</span>
        </Link>
    )
}



export function HrefPill ({href, text, onClick, style, newTab}) {
    return (
        <a href={href} onClick={onClick} style={style} target={newTab && '_blank'}>
            <div>{text}</div>
            <span className='material-symbols-rounded'>chevron_right</span>
        </a>
    )
}



export function ButtonPill ({text, onClick, style}) {
    return (
        <div onClick={onClick} style={style}>
            <div>{text}</div>
            <span className='material-symbols-rounded'>chevron_right</span>
        </div>
    )
}