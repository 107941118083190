import React from 'react'
import { Column } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX';
import { Helmet } from 'react-helmet';





function PrivacyPolicy() {
    const { app, theme } = useGlobal();


    return (
      <Column style={{ margin: app.isMobile ? '50px 10px 0px 10px' : '50px 200px 0px 200px', paddingBottom: 100, lineHeight: '1.6' }}>
        <Helmet>
            <title>Privacy - Gateway Forge</title>
            <meta name="description" content="Privacy policy." />

            <meta property="og:url" content="https://gatewayforge.com/privacy" />
            <meta property="og:title" content="Privacy - Gateway Forge" />
            <meta property="og:description" content="Privacy policy." />

        </Helmet>

        <h2>Privacy Policy</h2>
        <div>Effective Date: July 10, 2024</div>
  
        <h3>Introduction</h3>
        <p>
          Welcome to Gateway Forge ("we", "our", "us"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website <a href='https://gatewayforge.com'>gatewayforge.com</a> (the "Site") and use our services.
        </p>
  
        <h3>Information We Collect</h3>
        <p>We may collect the following types of information:</p>
        <ul>
          <li><strong>Personal Information:</strong> When you sign up for our waitlist, participate in surveys, or interact with our Site, we may collect personal information such as your name, email address, and any other information you voluntarily provide.</li>
          <li><strong>Usage Data:</strong> We may collect information about how you use our Site, such as your IP address, browser type, pages visited, and the time and date of your visit. This information helps us understand how our Site is being used and improve our services.</li>
        </ul>
  
        <h3>How We Use Your Information</h3>
        <p>We use the information we collect for the following purposes:</p>
        <ul>
          <li><strong>To Provide and Improve Our Services:</strong> We use your information to provide, maintain, and improve our Site and services.</li>
          <li><strong>To Communicate with You:</strong> We may use your personal information to contact you with updates, promotional materials, and other information related to our services.</li>
          <li><strong>To Analyze and Understand Our Audience:</strong> We use usage data to understand our audience's preferences and improve our Site's functionality and user experience.</li>
        </ul>
  
        <h3>Sharing Your Information</h3>
        <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except in the following circumstances:</p>
        <ul>
          <li><strong>Service Providers:</strong> We may share your information with third-party service providers who assist us in operating our Site, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.</li>
          <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
        </ul>
  
        <h3>Data Security</h3>
        <p>We implement a variety of security measures to maintain the safety of your personal information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
  
        <h3>Your Rights</h3>
        <p>You have the right to:</p>
        <ul>
          <li><strong>Access and Update Your Information:</strong> You can request access to your personal information and ask us to update or correct any inaccuracies.</li>
          <li><strong>Opt-Out:</strong> You can opt-out of receiving promotional communications from us by following the unsubscribe instructions provided in those communications.</li>
        </ul>
  
        <h3>Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. Your continued use of the Site after any changes take effect will constitute your acknowledgment and acceptance of the changes.</p>
  
        <h3>Contact Us</h3>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
        <p><strong>Gateway Forge</strong><br />mike@gatewayforge.com</p>
      </Column>
    );
  }
  

export default PrivacyPolicy