import React, { useEffect, useState } from 'react'
import { Column, Row } from './dom';
import { useGlobal } from '../../utility/CTX';




export function Slideshow({ imgs = [], children, style, ...props }) {
    const { theme } = useGlobal();
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prev => (prev + 1) % imgs.length);
        }, 5000);
        
        return () => {
            clearInterval(interval);
        }
    }, [imgs]);


    


    return <Column style={{alignItems: 'center', ...style}} {...props}>
        {children}

            
        <img src={imgs[index]} style={{
            width: '100%',
            height: 'auto',
            margin: 0
        }} />
        <Row style={{color: theme.txt_dark, fontSize: '0.8em'}}>
            <i>pre-alpha</i>
        </Row>
    </Column>
};












export const Image = ({imgUrl, height}) => {
    return <div style={{
        backgroundImage: `url(${imgUrl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}></div>
}