// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwZUYTm1SAbPknZBqxVsvJALFG8oxNxhQ",
  authDomain: "creative-monkey-ecosystem.firebaseapp.com",
  projectId: "creative-monkey-ecosystem",
  storageBucket: "creative-monkey-ecosystem.appspot.com",
  messagingSenderId: "850348107694",
  appId: "1:850348107694:web:823c19802f0f47fb1eca3f",
  measurementId: "G-SZ7339ZYGB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const cloud = getStorage(app);

console.log(db);

export { app, auth, analytics, db, cloud };