import React, { useEffect, useMemo, useState } from 'react';
import { Column, Row } from '../../layout/components/dom';
import { Slideshow } from '../../layout/components/image';

import { GlobalContext, useGlobal } from '../../utility/CTX';
import { LinkPill } from '../../layout/components/button';
import {Trademark} from '../../layout/components/dom';
import { getDownloadURL, ref } from 'firebase/storage';
import { cloud } from '../../utility/firebase';
import Section from './Section';
import { Helmet } from 'react-helmet';





function Home() {
    const { app, theme, dispatch } = useGlobal();
    const baseUrl = useMemo(() => 'site/assets/images')

    useEffect(() => {
        const paths = [
            `${baseUrl}/mockup1.png`,
            `${baseUrl}/mockup3.png`,
            `${baseUrl}/mockup4.png`,
            `${baseUrl}/mockup5.png`,
        ];
        const fetch_mockups = async (paths) => {
            try {
                const urls = await Promise.all(
                    paths.map(async (path) => {
                      const storageRef = ref(cloud, path);
                      const url = await getDownloadURL(storageRef);
                      return url;
                    })
                );
                dispatch({type: 'SET_MOCKUP_URLS', urls });
                console.log(urls);
            } catch (error) {
                console.log(error);
            }
        };
        fetch_mockups(paths);
    }, [])


    

  return (
    <Column style={{justifyContent: 'center', flexGrow: 1}}>
        <Helmet>
            <title>Home - Gateway Forge</title>
            <meta name="description" content="Learn about a new worldbuilding platform currently under development." />

            <meta property="og:url" content="https://gatewayforge.com/" />
            <meta property="og:title" content="Home - Gateway Forge" />
            <meta property="og:description" content="Learn about a new worldbuilding platform currently under development." />

        </Helmet>

        <Slideshow imgs={app.mockupUrls}>
            <h1 style={{top: 0, left: 35, fontWeight: 400, textAlign: 'center'}}>Interactive <span style={{color: theme.green}}>Worldbuilding</span> and <span style={{color: theme.orange}}>Story Development</span></h1>
            <WaitlistButton />
            <div style={{height: 20}} />
        </Slideshow>       

        

        <div style={{height: 60}} />

    </Column>
  )
};



const WaitlistButton = () => {
    const { theme, dispatch } = useGlobal();
    const [hover, setHover] = useState(false);


    return <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <LinkPill to='/waitlist' text='Join the Waitlist' onClick={() => dispatch({ type: 'SET_PAGE', page: 'Waitlist'})} 
            style={{transition: 'background 0.2s ease, color 0.2s ease', border: `1px solid ${theme.blue}`, color: hover ? theme.bg2 : theme.blue, background: hover && theme.blue, borderRadius: 50, padding: '0px 5px 0px 15px'}} />
    </div>
}





export default Home